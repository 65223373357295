<template>
  <div>
    <v-container
      fill-height
      fluid
      grid-list-xl
    >
      <v-layout
        justify-center
        wrap
      >
        <v-flex
          md12
        >
          <material-card
            :title="userNameEmail"
            color="green"
            text=""
          >
            <v-data-table
              :headers="headers"
              :items="items"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td>
                  <v-btn
                    :to="{ name: 'Заказ', params: { id: item.id } }"
                    small
                    flat
                    icon
                    color="#1867c0!important">
                    <v-icon>
                      mdi-alert-circle-outline
                    </v-icon>
                  </v-btn>
                </td>
                <td>{{ item.created | date }} {{ item.created | time }}</td>
                <td>{{ item.count }}</td>
                <td class="text-xs-right">{{ item.price }}</td>
                <td>{{ item.hasPayed ? 'Да' : 'Нет' }}</td>
                <td><span v-if="item.payedDate">{{ item.payedDate | date }} {{ item.payedDate | time }}</span></td>
                <td>{{ item.hasReceived ? 'Да' : 'Нет' }}</td>
                <td><span v-if="item.receivedDate">{{ item.receivedDate | date }} {{ item.receivedDate | time }}</span></td>
              </template>
            </v-data-table>
          </material-card>
          <material-card
            title="Правила"
            color="green"
            text=""
          >
            <v-data-table
              :headers="headersRules"
              :items="benefitRules"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td>{{ item.type }}</td>
                <td>{{ item.accumulationPeriod }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.utcOffset }}</td>
                <td>{{ item.createdOn | date }} {{ item.createdOn | time }}</td>
                <td>{{ item.modifiedOn | date }} {{ item.modifiedOn | time }}</td>
              </template>
            </v-data-table>
          </material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    id: null,
    headers: [
      {
        sortable: false,
        text: ''
      },
      {
        sortable: true,
        text: 'Дата',
        value: 'name'
      },
      {
        sortable: true,
        text: 'Количество',
        value: 'city'
      },
      {
        sortable: true,
        text: 'Цена',
        value: 'salary',
        align: 'right'
      },
      {
        sortable: true,
        text: 'Оплачен',
        value: 'hasReceived'
      },
      {
        sortable: true,
        text: 'Дата оплаты',
        value: 'payedDate'
      },
      {
        sortable: true,
        text: 'Получен',
        value: 'hasReceived'
      },
      {
        sortable: true,
        text: 'Дата получения',
        value: 'ReceivedDate'
      }
    ],
    headersRules: [
      {
        sortable: true,
        text: 'Тип',
        value: 'type'
      },
      {
        sortable: true,
        text: 'Период накопления',
        value: 'accumulationPeriod'
      },
      {
        sortable: true,
        text: 'Значение',
        value: 'value'
      },
      {
        sortable: true,
        text: 'Смещение',
        value: 'utcOffset'
      },
      {
        sortable: true,
        text: 'Создано',
        value: 'createdOn'
      },
      {
        sortable: true,
        text: 'Изменено',
        value: 'modifiedOn'
      },

    ],
    items: [ ],
    benefitRules: [ ],
    user: {}
  }),
  computed: {
    userNameEmail () {
      if (this.user) {
        return this.user.name + '(' + this.user.email + ')'
      } else {
        return ''
      }
    }
  },
  created () {
    this.id = this.$route.params.id
  },
  mounted () {
    this.getUser()
    this.getOrders()
    this.getBenefitRules()
  },
  methods: {
    getUser () {
      this.$http.get('/vendingprofiles/' + this.id)
        .then(response => {
          this.user = response.data
        })
    },
    getOrders () {
      this.$http.get('/vendingprofiles/' + this.id + '/orders')
        .then(response => {
          this.items = response.data
        })
    },
    getBenefitRules () {
      this.$http.get('/vendingprofiles/' + this.id + '/benefit-rules')
        .then(response => {
          this.benefitRules = response.data
        })
    }
  }
}
</script>
